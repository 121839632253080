<template>
  <div>
    <!-- Banner ads -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Business Impression Info
    </div>
    <b-card>
      <b-skeleton-wrapper
        :loading="selectedGroupDetailLoading"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-row
          v-if="selectedGroupDetail"
          class="pt-1 px-1"
        >
          <b-col
            class="profile p-1"
          >
            <span
              class="lead collapse-title bannerAdsClass"
            >
              <b-media class="mediaBanner">
                <span class="d-block font-weight-bolder text-nowrap id">
                  Free Impressions :
                </span>
              </b-media>
              <span class="pr-1">
                {{ selectedGroupDetail.resources.length > 0 ? Math.floor(selectedGroupDetail.resources[0].free_impressions) : 0 }}
              </span>
            </span>
          </b-col>
          <b-col
            class="profile p-1"
          >
            <span
              class="lead collapse-title bannerAdsClass"
            >
              <b-media class="mediaBanner">
                <span class="d-block font-weight-bolder text-nowrap id">
                  Paid Impressions :
                </span>
              </b-media>
              <span class="pr-1">
                {{ selectedGroupDetail.resources.length > 0 ? Math.floor(selectedGroupDetail.resources[0].paid_impressions) : 0 }}
              </span>
            </span>
          </b-col>
        </b-row>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BMedia,
  BSkeleton,
  BSkeletonWrapper,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BMedia,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedGroupDetail: null,
      selectedGroupDetailLoading: false,
    }
  },
  async mounted() {
    await this.getUserPmpCampaigns()
  },
  methods: {
    async getUserPmpCampaigns() {
      this.selectedGroupDetailLoading = true
      await this.$store.dispatch('admin/getUserGroups', { userHasGroupId: this.$route.params.userId })
      this.selectedGroupDetail = await this.$store.getters['admin/getUserHasGroup']
      this.selectedGroupDetailLoading = false
    },
  },
}
</script>
    <style scoped>
    .profile{
      border: none;
      margin-bottom: 1rem;
      box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 7px;
    }
    .mediaBanner{
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 3px;
    }
    .switches {
      border-radius: 25px;
    }
    .id{
      font-size: 14px;
      letter-spacing: 0px;
      color: #414141;
      font-weight: 600;
      font-family: "Montserrat";
      padding-left: 15px;
    }
    .buttonlink{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
    }
    .bannerAdsClass{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 640px) {
      .buttons{
        padding: 6px 9px;
        font-size: 12px;
      }
      .campaign-text{
        font-size: 12px;
      }
    }
    @media (max-width: 640px) {
      .buttons{
        font-size: 10px;
      }
      .campaign-text{
        font-size: 10px;
        padding-left: 0px
      }
    }
    </style>
