<template>
  <div>
    <!-- Banner ads -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Internal Ad Campaigns
    </div>
    <b-card>
      <b-skeleton-wrapper
        :loading="bannerCampaignLoading"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-row>
          <b-col
            v-for="campaign in allBannerCampaigns.slice(0, 3)"
            :key="campaign.name"
            class="profile p-1 m-1"
          >
            <span
              class="lead collapse-title bannerAdsClass"
            >
              <b-media class="mediaBanner">
                <span class="d-block font-weight-bolder text-nowrap id text-truncate">
                  {{ campaign.name }}
                </span>
              </b-media>
            </span>
          </b-col>
        </b-row>
        <div
          v-if="allBannerCampaigns.length === 0 && bannerCampaignLoading === false"
          class=" d-flex justify-content-center align-items-center campaign-text"
        >
          No Internal Ad Campaigns Found
          <b-button
            variant="primary"
            class="float-right ml-auto buttons"
            @click="viewAllBannerAds"
          >
            Add Internal Ad Campaigns
          </b-button>
        </div>
        <div
          v-else
          class="buttonlink"
        >
          <b-button
            variant="link"
            class="p-0"
            @click="viewAllBannerAds"
          >
            View All
          </b-button>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BMedia,
  BSkeleton,
  BSkeletonWrapper,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BMedia,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MediaType,
      allBannerCampaigns: [],
      bannerCampaignLoading: false,
    }
  },
  async mounted() {
    await this.getUserBannerCampaigns()
  },
  methods: {
    getUserBannerCampaigns() {
      this.bannerCampaignLoading = true
      this.$axios
        .get(`admin/banner-campaign/${this.$route.params.userId}`)
        .then(({ data: { data } }) => {
          this.allBannerCampaigns = data
          this.bannerCampaignLoading = false
        }).catch(error => ({ error }))
    },
    viewAllBannerAds() {
      this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
    },
  },
}
</script>
<style scoped>
.profile{
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 7px;
}
.mediaBanner{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 3px;
}
.switches {
  border-radius: 25px;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
  max-width: 220px;
}
.buttonlink{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
.campaign-text{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.bannerAdsClass{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 640px) {
  .buttons{
    padding: 6px 9px;
    font-size: 12px;
  }
  .campaign-text{
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  .buttons{
    font-size: 10px;
  }
  .campaign-text{
    font-size: 10px;
    padding-left: 0px
  }
}
</style>
