<template>
  <div>
    <!--    <b-row class="match-height">-->
    <!--      <b-col-->
    <!--        lg="8"-->
    <!--        md="6"-->
    <!--      >-->
    <!--        <user-detail />-->
    <!--      </b-col>-->
    <!--      <b-col-->
    <!--        lg="4"-->
    <!--        md="6"-->
    <!--      >-->
    <!--        <user-stats />-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <UserGroupsCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserBannerAdsCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserBusinessImpressionInfo />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserPmpAdsCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserMediaStorageCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserVenuesCard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// import UserDetail from '../../../components/admin/UserDetail.vue'
// import UserStats from '../../../components/admin/UserStats.vue'
import UserGroupsCard from '@/components/admin/UserGroupsCard.vue'
import UserBannerAdsCard from '@/components/admin/UserBannerAdsCard.vue'
import UserPmpAdsCard from '@/components/admin/UserPmpAdsCard.vue'
import UserMediaStorageCard from '@/components/admin/UserMediaStorageCard.vue'
import UserVenuesCard from '@/components/admin/UserVenuesCard.vue'
import UserBusinessImpressionInfo from '@/components/admin/UserBusinessImpressionInfo.vue'

export default {
  components: {
    UserVenuesCard,
    UserMediaStorageCard,
    UserBannerAdsCard,
    UserPmpAdsCard,
    UserGroupsCard,
    UserBusinessImpressionInfo,
    BRow,
    BCol,
  },
}
</script>
